import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, LWrap, CNewsList, LPlan, CPagingList, CCategoryNav } from "../../components/_index"
import releaseChoice from "../../utils/release-choice"
import releaseGet from "../../utils/release-get"

// markup
const SubPage = ({ data, pageContext }: { data: any, pageContext: { limit: number, skip: number, totalCount: number, current: number } }) => {
  const frontmatter = {
    title: "ニュースリリース",
    description: "ザ ロイヤルパークホテル 京都三条のニュースリリースページです。"
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            en: <>news release</>,
            ja: 'ニュースリリース'
          },
          imgList: [
            {
              img: {
                src: '/assets/images/news/kv.png'
              },
              imgSp: {
                src: '/assets/images/news/kv.png'
              }
            }
          ]
        }}
      >
        <CBreadCrumb data={{
          parent: [
          ],
          current: {
            label: frontmatter?.title
          }
        }} />
      </CJumbotron>
      <section className="l_sect u_pt120">
        <LWrap>
          <CCategoryNav data={[
            {
              label: 'お知らせ',
              link: {
                href: '/information/',
                blank: false,
              },
              current: false,
            },
            {
              label: 'ニュースリリース',
              link: {
                href: '/release/',
                blank: false,
              },
              current: true,
            }
          ]} />
          <CNewsList data={releaseChoice(releaseGet(), pageContext.limit + pageContext.skip, pageContext.skip)} />
          <CPagingList totalCount={pageContext.totalCount} path="/release/" perPage={pageContext.limit} current={pageContext.current} />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  )
}

export default SubPage;
